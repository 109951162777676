import React, { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AiOutlineShoppingCart } from "react-icons/ai";
import ZendropModal from "./ZendropModal";
import Skeleton from '@mui/material/Skeleton';
import "../styles/button.css"
import "../styles/style.css"

const ProductCard = ({ data, i }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showImage, setShowImage] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const suppliers = useSelector((state) => state.filters.suppliers);
  const [imageExists, setImageExists] = useState(true);

  const handleImageError = () => {
    setImageExists(false);
  };
  const goToProductDetail = () => {
    dispatch(
      {
        type: 'LOADING',
        payload: true,
      }
    )
    navigate(`/product-detail/${data.id}`);
  }

  const onImageError = (e) => {
    // e.target.src = "http://localhost:8000/files/4/img6.webp";
    setShowImage(false);
  }

  const isMobileScreen = window.innerWidth < 768;
  return (
    <>
      <div className="p-3 mainDiv border border-gray-300 custom-rounded-lg flex flex-col space-y-2 m-2 alignTop"
      style={isMobileScreen ? { width: "20rem", maxHeight: "32rem" } : { width: "18rem", maxHeight : "30rem" }}
      >

        <div className="w-full cursor-pointer rounded-lg" onClick={goToProductDetail}>
          {/* size of data.image > 0 */}
          {showImage && data.image.length > 0 && (
            <img
              onError={onImageError}
              src={data.image[0]}
              loading="lazy"
              className="productImage h-[55%] w-full cursor-pointer rounded-lg"
            />
          )
          
          }
          {
            // console.log(data.image[0])
          }
        </div>

        <div className="description flex flex-col space-y-2 h-fit">
          <div className="flex flex-col">
            <h1 className="text-gray-800 font-semibold text-lg">
              {data.name.substring(0, 22)}{" "}
              {data.name.length > 22 ? "..." : null}
            </h1>
            <p className="text-gray-500 text-sm">
              By{" "}
              <Link
                className="text-primary hover:underline"
                to={`/supplier/${data.supplier_id}`}
              >
                {suppliers.find((supplier) => supplier.id === data.supplier_id)?.name}
              </Link>
            </p>
          </div>
          <div onClick={goToProductDetail} className="cursor-pointer">
            <div className="mb-2 flex items-center justify-between">
              <div className="flex flex-col">
                <p className="text-sm text-gray-500">Listing Cost</p>
                <p className="text-gray-900 font-semibold">$
                  {data ? data.listing_cost.toLocaleString() : null}
                </p>

              </div>
              <div className="flex flex-col">
                <p className="text-sm text-gray-500">Retail Price</p>
                <p className="text-gray-900 font-semibold">${data ? data.sale_price.toLocaleString() : null}</p>
              </div>
            </div>
            <div className="flex flex-col text-sm">
              <p className="text-gray-500">
                {<FontAwesomeIcon icon={'shipping-fast'} />} Shipping Details
              </p>
              <p>
                <span className="text-gray-800 font-medium text-sm">
                  ${data?.us_shipping_cost}
                </span>
                <span className="ml-2 text-gray-500 font-normal text-sm">
                  ({data?.us_shipping_time} days)
                </span>
              </p>
            </div>

          </div>
          <div className="flex items-center justify-between text-sm pt-1 font-medium">
            <button
              className="px-8 py-2 custom-rounded-lg text-primary bg-gray-300 hover:bg-gray-400 view_btn"
              style={{ width: "43%" }}
              onClick={goToProductDetail}
            >
              View
            </button>

            <button
              className="bg-primary text-white custom-rounded-lg outline-none py-2 px-3 flex items-center gap-x-1 hover:bg-blue-700/90"
              // onClick={() => { window.open(data?.link, '_blank') }}
              onClick={() => setShowModal(true)}
            >
              <AiOutlineShoppingCart /> <p>Go to supplier</p>
            </button>
          </div>
        </div>
      </div>
      {showModal ? <ZendropModal link={data?.link} setShowModal={setShowModal} /> : null}
    </>
  );
};

export default ProductCard;
