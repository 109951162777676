import React from "react";
import { Modal } from "./Modal";
import { AiOutlineShoppingCart } from "react-icons/ai";
import ConfirmationModal from "./ConfirmationModal";

const ZendropModal = ({ link, setShowModal }) => {

  const goToLinkHandler = () => {
    window.open(link, "_blank");
    setShowModal(false);
  }

  return (
    <ConfirmationModal
      heading="Redirect to Supplier"
      message="You can directly add products from products via Supplier to your store."
      onCancel={() => setShowModal(false)}
      onConfirm={goToLinkHandler}
      loading={false}
    />
    // <Modal width="w-[40%]">
    //   <div className="flex flex-col space-y-3">
    //     <h1 className="title">Rediret to Zendrop</h1>
    //     <p className="subtitle text-sm">
    //       You can directly add products from products via Zendrop to your store.
    //     </p>
    //     <div className="flex items-center ml-auto text-sm pt-1 font-medium space-x-3">
    //       <button
    //         className="px-8 py-2 rounded-lg text-primary bg-gray-200 hover:bg-gray-300/80"
    //         onClick={() => setShowModal(false)}
    //       >
    //         Cancel
    //       </button>
    //       <button onClick={goToLinkHandler} className="bg-primary text-white rounded-lg outline-none px-3 py-2 flex items-center gap-x-2 hover:bg-blue-700/90">
    //         <AiOutlineShoppingCart /> <p>Go to supplier</p>
    //       </button>
    //     </div>
    //   </div>
    // </Modal>
  );
};

export default ZendropModal;
